<template>
  <div class="order">
    <van-tabs v-model="active" @click="onClick">
      <van-tab title="全部订单">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="order_details" v-show="orderShow" v-for="item in orderList" :key="item.id">
            <van-cell-group inset>
              <van-cell>
                <div class="order_num">
                  <span>订单号：</span>
                  <span>{{item.account}}</span>
                </div>
                <div class="order_type">
                  <span>{{item.status|payType}}，{{item.callBackStatus|callBackType}}</span>
                </div>
              </van-cell>
              <div class="goods">
                <van-image width="6.5em" height="6.5em" :src="item.showImage" />
                <div>
                  <p class="goods_title">{{item.skuTitle}}</p>
                  <p class="recharge_account">{{item.account}}</p>
                  <p class="goods_retailPrice">￥{{item.paymentAmout}}</p>
                </div>
              </div>
              <van-divider />
              <p class="creatTime">{{item.creationTime|timestampToTime}}</p>
            </van-cell-group>
          </div>
        </van-pull-refresh>
        <van-pagination v-show="paginationShow" v-model="currentPage" :total-items="total" :items-per-page="5" @change='handchange' />
        <van-empty v-show="!orderShow" description="暂无订单" />
      </van-tab>
      <van-tab title="未支付">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="order_details" v-show="orderShow" v-for="item in orderList" :key="item.id">
            <van-cell-group inset>
              <van-cell>
                <div class="order_num">
                  <span>订单号：</span>
                  <span>{{item.account}}</span>
                </div>
                <div class="order_type">
                  <span>{{item.status|payType}}，{{item.callBackStatus|callBackType}}</span>
                </div>
              </van-cell>
              <div class="goods">
                <van-image width="6.5em" height="6.5em" :src="item.showImage" />
                <div>
                  <p class="goods_title">{{item.skuTitle}}</p>
                  <p class="recharge_account">{{item.account}}</p>
                  <p class="goods_retailPrice">￥{{item.paymentAmout}}</p>
                </div>
              </div>
              <van-divider />
              <p class="creatTime">{{item.creationTime|timestampToTime}}</p>
            </van-cell-group>
          </div>
          <van-pagination v-show="paginationShow" v-model="currentPage" :total-items="total" :items-per-page="5" @change='handchange' />
          <van-empty v-show="!orderShow" description="暂无订单" />
        </van-pull-refresh>
      </van-tab>
      <van-tab title="支付成功">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="order_details" v-show="orderShow" v-for="item in orderList" :key="item.id">
            <van-cell-group inset>
              <van-cell>
                <div class="order_num">
                  <span>订单号：</span>
                  <span>{{item.account}}</span>
                </div>
                <div class="order_type">
                  <span>{{item.status|payType}}，{{item.callBackStatus===0?'充值中':(item.callBackStatus|callBackType)}}</span>
                </div>
              </van-cell>
              <div class="goods">
                <van-image width="6.5em" height="6.5em" :src="item.showImage" />
                <div>
                  <p class="goods_title">{{item.skuTitle}}</p>
                  <p class="recharge_account">{{item.account}}</p>
                  <p class="goods_retailPrice">￥{{item.paymentAmout}}</p>
                </div>
              </div>
              <van-divider />
              <p class="creatTime">{{item.creationTime|timestampToTime}}</p>
            </van-cell-group>
          </div>
          <van-pagination v-show="paginationShow" v-model="currentPage" :total-items="total" :items-per-page="5" @change='handchange' />
          <van-empty v-show="!orderShow" description="暂无订单" />
        </van-pull-refresh>
      </van-tab>
      <van-tab title="支付失败">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="order_details" v-show="orderShow" v-for="item in orderList" :key="item.id">
            <van-cell-group inset>
              <van-cell>
                <div class="order_num">
                  <span>订单号：</span>
                  <span>{{item.account}}</span>
                </div>
                <div class="order_type">
                  <span>{{item.status|payType}}，{{item.callBackStatus|callBackType}}</span>
                </div>
              </van-cell>
              <div class="goods">
                <van-image width="6.5em" height="6.5em" :src="item.showImage" />
                <div>
                  <p class="goods_title">{{item.skuTitle}}</p>
                  <p class="recharge_account">{{item.account}}</p>
                  <p class="goods_retailPrice">￥{{item.paymentAmout}}</p>
                </div>
              </div>
              <van-divider />
              <p class="creatTime">{{item.creationTime|timestampToTime}}</p>
            </van-cell-group>
          </div>
          <van-pagination v-show="paginationShow" v-model="currentPage" :total-items="total" :items-per-page="5" @change='handchange' />
          <van-empty v-show="!orderShow" description="暂无订单" />
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <div class="sj_order" @click="oder">
      <van-icon name="orders-o" />
      <p>首页</p>
    </div>
  </div>
</template>

<script>
import { api_order } from "@/api/api.js";
export default {
  data () {
    return {
      active: 0,
      orderShow: true,
      IdentityKey: '',
      orderList: [],
      total: "",
      currentPage: 1,
      paginationShow: false,
      isLoading : false,
    }
  },
  created () {
    this.supplierId = this.$route.query.supplierId
    this.IdentityKey = sessionStorage.getItem('code')
    if (this.IdentityKey) {
      this.getAccountOrder()
    } else {
      this.getAllOrder()

    }
  },
  methods: {
    getAccountOrder () {
      let status = ''
      if (this.active == 0) {
        status = ''
      } else {
        status = this.active - 1
      }
      const params = {
        Status: status,
        IdentityKey: this.IdentityKey,
        Page: this.currentPage,
        Size: 5
      }
      api_order(params).then(res => {
        if (res.data.code == 0) {
          this.orderList = res.data.data
          this.total = res.data.total
          if (this.orderList.length == 0) {
            this.orderShow = false
          } else {
            this.orderShow = true
          }
        } else {
          this.$toast.success(res.data.message);
          this.orderShow = false
        }
        if (this.total > 5) {
          this.paginationShow = true
        } else {
          this.paginationShow = false
        }
      })
    },
    getAllOrder () {
      let status = ''
      if (this.active == 0) {
        status = ''
      } else {
        status = this.active - 1
      }
      const params = {
        Status: status,
        Page: this.currentPage,
        Size: 5
      }
      api_order(params).then(res => {
        if (res.data.code == 0) {
          this.orderList = res.data.data
          this.total = res.data.total
          if (this.orderList.length == 0) {
            this.orderShow = false
          } else {
            this.orderShow = true
          }
        } else {
          this.$toast.success(res.data.message);
          this.orderShow = false
        }
        if (this.total > 5) {
          this.paginationShow = true
        } else {
          this.paginationShow = false
        }
      })
    },
    onClick (title) {
      this.currentPage = 1
      if (title == "全部订单") {
        this.active = 0
      }
      if (title == "未支付") {
        this.active = 1
      }
      if (title == "支付成功") {
        this.active = 2
      }
      if (title == "支付失败") {
        this.active = 3
      }
      if (this.IdentityKey) {
        this.getAccountOrder()
      } else {
        this.getAllOrder()
      }
    },
    handchange (val) {
      this.currentPage = val
      if (this.IdentityKey) {
        this.getAccountOrder()
      } else {
        this.getAllOrder()
      }
    },
    oder () {
      this.$router.push({
        path: '/signergys',
        query: {
          supplierId: this.supplierId,
        }
      })
    },
    onRefresh () {
      setTimeout(() => {
        if (this.IdentityKey) {
          this.getAccountOrder()
        } else {
          this.getAllOrder()
        }
        this.$toast('刷新成功');
        this.isLoading = false;
      }, 1000);
    },
  },
  filters: {
    payType (num) {
      if (num == 0) {
        return "待支付"
      } else if (num == 1) {
        return "支付成功"
      } else if (num == 2) {
        return "支付失败"
      } else if (num == 3) {
        return "取消"
      } else if (num == -1) {
        return "退款"
      }
    },
    callBackType (num) {
      if (num == 0) {
        return "未充值"
      } else if (num == 1) {
        return "充值成功"
      } else if (num == 2) {
        return "充值失败"
      } else if (num == -1) {
        return "退款成功"
      }
    },
    timestampToTime (timestamp) {
      let date = new Date(timestamp)
      let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    }
  }
}
</script>

<style lang="scss" scoped>
.order {
  width: 100%;
  height: 100%;
  background: #f4f6f9;
  .van-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ::v-deep .van-tabs__wrap {
      overflow: inherit;
      margin-bottom: 1em;
      .van-tab__text {
        margin-top: 1em;
      }
    }
    ::v-deep .van-tabs__content {
      height: 100%;
      overflow: scroll;
      .van-tab__pane {
        background: #f4f6f9;
        padding: 2em;
        .order_details {
          border-radius: 1em;
          box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
          background: #fff;
          height: 100%;
          margin-bottom: 1em;
          .van-cell-group {
            .van-cell {
              padding: 1em 0.5em;
              .van-cell__value {
                display: flex;
                justify-content: space-between;
                .order_num {
                  color: #868686;
                }
                .order_type {
                  color: orange;
                }
              }
            }
            .goods {
              display: flex;
              justify-content: flex-start;
              margin: 2em 0.1em;
              .van-image {
                margin-left: 4em;
              }
              div {
                margin-left: 4em;
                .goods_title {
                  color: dimgray;
                  font-size: 1.5em;
                }
                .recharge_account {
                  font-size: 1.3em;
                  color: dimgray;
                  margin: 0.5em 0 1em 0;
                }
                .goods_retailPrice {
                  color: red;
                  font-size: 1.5em;
                }
              }
            }
            .creatTime {
              color: #868686;
              padding: 0em 0em 1em 1em;
            }
          }
        }
        .van-empty {
          .van-empty__image {
            width: 6em;
            height: 6em;
          }
        }
      }
    }
  }
  .sj_order {
    width: 5.5em;
    height: 5.5em;
    position: fixed;
    display: flex;
    border-radius: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: red;
    box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
    background: #fff;
    right: 5%;
    top: 70%;
    border-radius: 50%;
    .van-icon {
      font-size: 2.5em;
    }
  }
}
</style>